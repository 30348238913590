import { navigate } from "gatsby";
import React from "react";
import Button from "../components/form/button/Button";
import Layout from "../layout/Layout";

function accountSuccess() {
  return (
    <Layout>
      <h3>Your account has been successfully created.</h3>
      <Button onClick={() => navigate("/")}>continue shopping</Button>
    </Layout>
  );
}

export default accountSuccess;
